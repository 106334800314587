import React from 'react';
import styled from 'styled-components';

import {
  SectionComponent,
  HeadlineComponent,
} from '../styles/StyledComponents';
import SEO from '../utils/seo';
import Layout from '../layout/Layout';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 2rem;
`;

export const ParagraphComponent = styled.p`
  font-size: ${({ size }) => size || '1rem'};
  font-weight: 400;
  text-align: ${({ align }) => align && align};
  padding-bottom: ${({ pb }) => pb || '1.5rem'};
`;

const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: 600;
  color: ${({ theme}) => theme.colors.primary};
  margin-bottom: 1rem;
`;

const List = styled.ol`
  margin-left: 4rem;
  margin-bottom: 1rem;
`;

const ListItem = styled.li`
margin-bottom: 1rem;
`

function FAQPage() {

  return (
    <Layout>
      <SEO
        title="Terms & Conditions"
        link="https://teampoker.com/terms"
        description="TeamPoker® is “The Patented Card Game Process” for the Tournament Poker Team Sports Model."
      />
      <SectionComponent mg="1.5rem">
        <HeadlineComponent>Terms & Conditions</HeadlineComponent>
        <Container>
        <ParagraphComponent>
        All Terms of Service and Conditions for Team Poker® Live Poker Room use or On-line Poker Rooms are handled through direct licenses with Team Poker LLC. Please contact Team Poker LLC or one of its Licensed Associates if you want to use the Team Poker® Process or Any Associate Intellectual Property.
        </ParagraphComponent>
        <ParagraphComponent>
        Use of Team Poker® is restricted to only locals where it is legal to use. Any illegal betting or gambling with Team Poker® is strictly prohibited. Anyone that thinks that parties are using Team Poker® illegal should notify the applicable controlling authorities and contact us immediately.
        </ParagraphComponent>
        <Title>
        LICENSE RESTRICTIONS
        </Title>
        <ParagraphComponent>
        Unless authorized by the TEAM POKER LLC GAME SOFTWARE PRIME LICENSE or otherwise authorized directly by Team Poker LLC, Users and Licensees may not:
        </ParagraphComponent>
        <List>
          <ListItem>
          permit other individual(s) to use the Software or other Intellectual Property;
          </ListItem>
          <ListItem>
          modify, translate, reverse engineer, decompile, disassemble or create derivative works based upon the Licensed Software and Intellectual Property;
          </ListItem>
          <ListItem>
          copy the Software or Intellectual Property;
          </ListItem>
          <ListItem>
          rent, lease, transfer, sublicense or otherwise transfer rights to the Software and Intellectual Property;
          </ListItem>
          <ListItem>
          remove any proprietary notices or labels on the Software, including the code underlying the Software; or,
          </ListItem>
          <ListItem>
          use the Software and Intellectual Property for any reason other than the Limited License Commercial use as expressly identified by License.
          </ListItem>
        </List>
        <Title>
        THIRD PARTY WEBSITES
        </Title>
        <ParagraphComponent>
        Other Sites Are Not Our Responsibility This Site may link you to other websites that may contain information or material that some people may find objectionable. These other websites are not under our direct control and it is acknowledged that we are not responsible for the accuracy, copyright and trademark compliance, legality, decency or any other aspect of the content of such websites. Further, we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such third party content, goods or services available on or through any such website.
        </ParagraphComponent>
        <Title>
        GOVERNING LAW/JURISDICTION
        </Title>
        <ParagraphComponent>
        ALL ISSUES AND QUESTIONS CONCERNING THE CONSTRUCTION, VALIDITY, INTERPRETATION AND ENFORCEABILITY OF ANY LICENSE, THIS AGREEMENT OR THE RIGHTS AND OBLIGATIONS OF MEMBERS OR IN CONNECTION WITH ANY ASSOCIATED LICENSE, SITE, AND THE INTELLECTUAL PORPERTY, COPYWRIGHT, SOFTWARE AND THE PROCESS SHALL BE GOVERNED BY AND CONSTRUED IN ACCORDANCE WITH THE INTERNAL LAWS OF THE UNITED STATES OF AMERICA, STATE OF NEVADA WITHOUT GIVING EFFECT TO ANY CHOICE OF LAW OF CONFLICT OF LAW RULES OR PROVISIONS THAT WOULD CAUSE THE APPLICATION OF ANY OTHER JURISDICTIONS LAWS. The state and federal courts located in Las Vegas Nevada shall have exclusive jurisdiction over any suit or other proceeding arising out of or based upon any use of any licensing agreement, this Site our Associate Sites and Licenses and any direct or associated licenses, and by using the Site and Team Poker® Licenses, each user hereby waives any claim that he or she or their company is not subject personally to the jurisdiction of said courts or that any such suit or other proceeding is brought in an inconvenient forum or improper venue.
        </ParagraphComponent>
        </Container>

      </SectionComponent>
    </Layout>
  );
}

export default FAQPage;